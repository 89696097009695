<template>
  <div>
    <HomePageTop/>
    <header>
      <div>商品写真·撮影代行サービス</div>
    </header>
    <section class="headerSection">
      <div>ＥＣサイト販売はお客様にとって実際の商品が手に取れない為、写真で商品を判断するしかありません。</div>
      <div>商品写真は、最も売り上げを左右する重要な要素の一つです</div>
      <div>手間の掛かる商品撮影はお任せください。</div>
      <div style="margin-top: 20px">弊社の撮影スタジオにプロのカメラマンが常駐しており、ぜひご利用ください。</div>
      <el-link :href="downloadUrl+code" :underline="false" target="_blank">
        <div class="templateDownloadBtn">
          <img src="../../assets/churujinList/download.png" alt="">
          <div>撮影指示書</div>
        </div>
      </el-link>
    </section>
    <section class="footerSection">
      <div class="title">撮影料金（1商品につき）</div>
      <div class="photographyList">
        <div class="photographyContainer">
          <img src="../../assets/icon/orange.png" alt="">
          <div class="numText">1枚</div>
          <div class="priceContainer">
            <div>12</div>
            <div>元</div>
          </div>
        </div>
        <div class="photographyContainer">
          <img src="../../assets/icon/yellow.png" alt="">
          <div class="numText">２-５枚セット</div>
          <div class="priceContainer">
            <div>40</div>
            <div>元</div>
          </div>
        </div>
        <div class="photographyContainer">
          <img src="../../assets/icon/violet.png" alt="">
          <div class="numText">６-８枚セット</div>
          <div class="priceContainer">
            <div>60</div>
            <div>元</div>
          </div>
        </div>
        <div class="photographyContainer">
          <img src="../../assets/icon/blue.png" alt="">
          <div class="numText">8枚以上の枚数は</div>
          <div class="priceContainer">
            <div>7</div>
            <div>元 / 枚</div>
          </div>
        </div>
      </div>
      <div class="fontSize18" style="margin-bottom: 90px">例：10枚の場合　60元+14元 計：74元</div>
      <div class="fontSize24 fontWeightBold" style="margin-bottom: 23px">モデル撮影</div>
      <div class="fontSize18">モデル手配もお任せでください。モデルの費用はその都度見積もりをさせて頂きます。</div>
    </section>
    <footer>
      <div class="title">無料サービス</div>
      <div style="width: 1350px">
        <div class="fontSize18 fontWeightBold margin-bottom-4">１、マネキン、トルソーの使用</div>
        <div>アパレル商品の場合、平置きの写真だけではサイズや全体のイメージがわかりずらいことが多々有りますので、洋服などはより立体に、バックなどはマネキンに着用す
          るとサイズ感が簡単にわかります。
        </div>
        <div class="fontSize18 fontWeightBold margin-top-30 margin-bottom-4">２、スチーマーかけ</div>
        <div>シワ映りが心配な衣類の撮影に、ぜひご活用ください。</div>
        <div class="fontSize18 fontWeightBold margin-top-30 margin-bottom-4">３、反射素材</div>
        <div>アクセサリー類など、反射素材の写真は撮影後修正などの必要あり、そちらも追加料金なしで対応いたします。</div>
        <div class="fontSize18 fontWeightBold margin-top-30 margin-bottom-4">４、白抜き加工</div>
        <div>ＥＣサイトにより白背景でなければならないことがあり、そちらの加工も無料でさせて頂きます。</div>
        <div class="margin-top-30">特別のご要望がない場合は基本すべて写真を黙認で白抜き加工して、お渡し致します。</div>
        <div class="fontSize18 fontWeightBold margin-top-30 margin-bottom-4">５、写真のリネーム</div>
        <div>撮影したままのファイル名は間違いなどが起こりやすいので、商品ごとの管理番号にリネームを無料で行います。</div>
        <div class="fontSize18 fontWeightBold margin-top-30 margin-bottom-4">６、無料ロゴ付け</div>
        <div>
          公開の写真は簡単に使われることが多々あります、そんなことを防ぐために画像にショップのロゴを入れるのが有用な手段として使われています。
        </div>
        <div class="margin-top-30">お客様にご要望があれば無料でお付けいたします。</div>
        <div class="margin-top-30">その他記載していないサービスが必要な場合、お問い合わせください。</div>
      </div>
    </footer>
  </div>
</template>

<script>
import Foot from "../../components/foot/Foot";
import HomePageTop from "../../components/head/HomePageTop.vue";

export default {
  components: {
    Foot,
    HomePageTop
  },
  data() {
    return {
      code: '',
      downloadUrl: 'https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202307/64be37d45bbb5.xlsx?rand='
    }
  },
  created() {
    for (var i = 0; i < 7; i++) {
      this.code += parseInt(Math.random() * 10);
    }
  },
}
</script>

<style lang="scss" scoped>
header {
  background: url("../../assets/photographyBackground.png") no-repeat;
  width: 100%;
  height: 350px;
  background-size: 100% 100%;
  color: #FFFFFF;
  text-shadow: 0 0 27px rgba(0, 0, 0, 0.4);
  font-weight: bold;
  font-size: 48px;
  line-height: 350px;
  text-align: center;
}

.headerSection {
  background: #fff;
  width: 100%;
  height: 342px;
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  div {
    color: #333;
  }

  .templateDownloadBtn {
    display: flex;
    align-items: center;
    height: 34px;
    background: #B4272B;
    border-radius: 8px;
    justify-content: center;
    cursor: pointer;
    padding: 0 10px;
    margin-top: 29px;

    div {
      margin-left: 8px;
      color: #FFFFFF;
      font-size: 14px;
    }
  }
}

.footerSection {
  background: #F6F6F6;
  height: 596px;
  padding: 80px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    color: #333333;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 62px;
  }

  .photographyList {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 48px;
    width: 1159px;

    .photographyContainer {
      width: 220px;
      height: 130px;
      background: #FFFFFF;
      box-shadow: 0 0 10px 0 rgba(6, 0, 1, 0.05);
      border-radius: 12px;
      position: relative;


      img {
        position: absolute;
        left: 0;
        top: 0;
      }

      .numText {
        margin-top: 28px;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 21px;
      }

      .priceContainer {
        display: flex;
        justify-content: center;

        div:first-child {
          font-size: 30px;
          color: #B4272B;
          font-weight: bold;
        }

        div:last-child {
          font-size: 18px;
          margin-top: 11px;
        }
      }
    }
  }
}

footer {
  height: 973px;
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;

  .title {
    color: #333333;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 80px;
  }

  .margin-top-30 {
    margin-top: 30px;
  }

  .margin-bottom-4 {
    margin-bottom: 4px;
  }
}
</style>
